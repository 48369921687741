<template>
  <!-- 分组列表 -->
  <el-scrollbar v-if="type==1" style="height: 100%;width: 100%;" class="scrollbar">
    <div v-if="type==1">
      <div class="row " style="display: flex;justify-content: center;align-items: center;">
        <el-popover
          v-for="(grouping) in groupings"
          :key="grouping.id"
          trigger="hover"
          placement="bottom"
          width="1020"
          :name="grouping.name"
        >
          <div slot="reference" class="column" :name="grouping.name" style="position: relative;display: flex;justify-content: center;align-items: center;margin-left: 10px;margin-top: 10px;font-size: 14px;font-weight: bold;" @click="visible = !visible">
            <el-image :src="grouping.groupings[0].img" fit="fit" class="item-img" style="width: 50px;" />
            {{ grouping.name }}
            <i class="el-icon-delete" style="position: absolute; right: 10px; top:10px;" @click.stop="showDeDialog=true;deCartoonId=grouping.id;"></i>
          </div>
          <el-scrollbar class="scrollbar" style="width: 100%;height: 400px;">
            <div class="row" style="flex-wrap: wrap;width:100%;margin: 10px;">
              <div
                v-for="(item) in grouping.groupings"
                :key="item.id"
                class="column item"
                @click="groupingName=item.name;onGrouping(item.id);onBack()"
              >
                <el-image :src="item.img" fit="fit" class="item-img" />
                <label class="item-name">{{ item.name }}</label>
              </div>
            </div>
          </el-scrollbar>
        </el-popover>
      </div>
      <!-- <el-collapse>
        <el-collapse-item v-for="(grouping) in groupings" :key="grouping.id" :name="grouping.name">
          <template slot="title">
            {{ grouping.name }}<i class="el-icon-delete" style="margin-left: 10px;" @click.stop="showDeDialog=true;deCartoonId=grouping.id;"></i>
          </template>
          <div class="row" style="flex-wrap: wrap;width:100%;margin: 10px;">
            <div
              v-for="(item) in grouping.groupings"
              :key="item.id"
              class="column item"
              @click="groupingName=item.name;onGrouping(item.id);onBack()"
            >
              <el-image :src="item.img" fit="fit" class="item-img" />
              <label class="item-name">{{ item.name }}</label>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse> -->
      <div title="上传绘本" style="position: fixed; right: 20px;top: 80px;" @click="onOepnLocalFile">
        <input
          id="sourceFile"
          ref="localFileInput"
          multiple="true"
          accept=".zip"
          type="file"
          style="display: none"
          @change="handleFileChange"
        />
        <img src="../assets/images/home/上传视频.png" style="width:20px;" />
        <div v-if="groupings.length==0" style="width: 24px;text-align:center;font-size: 12px;color: #878889;">上传绘本</div>
        <div v-if="isDowJur" style="width: 24px;text-align:center;font-size: 12px;color: #878889;">处理中...</div>
      </div>
    </div>

    <el-dialog
      title="系统提示"
      :visible.sync="showDeDialog"
      width="30%"
    >
      <span>删除后此绘本将会消失，如需阅读需再次上传！</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDeDialog = false;">取 消</el-button>
        <el-button type="primary" @click="showDeDialog = false;deCartoon()">确 定</el-button>
      </span>
    </el-dialog>
  </el-scrollbar>
  <!-- 书本列表 -->
  <el-scrollbar v-else-if="type==2" style="height: 100%;width: 100%;">
    <div v-if="type==2" class="row" style="flex-wrap: wrap;width:100%;margin: 10px;">
      <div class="back" @click="type=1;books=[];onBack()">
        <i class="el-icon-back" style="font-size: 24px;"></i>
        {{ groupingName }}
      </div>
      <div
        v-for="(book) in books"
        :key="book.id"
        class="column item"
        @click="bookName=book.name;onBook(book.bid);"
      >
        <div
          class="book-num"
          :style="{background:book.num==0?'#F73943':'green'}"
        >已阅读 {{ book.num }} 次</div>
        <el-image :src="book.img" fit="fit" class="item-img" lazy />
        <label class="item-name">{{ book.name }}</label>
      </div>
    </div>
  </el-scrollbar>
  <div
    v-else
    style="position: relative; display: flex;justify-content: center;align-items: center;"
  >
    <div class="back" @click="type=2;contents=[];contentItem={};audio.pause();onBack();">
      <i class="el-icon-back" style="font-size: 24px;"></i>
      {{ bookName }}
    </div>
    <div v-if="contents.length>0" style="width: 100%;height: 100%;">
      <el-container style="width: 100%;height: 100%;">
        <!-- 绘本内容 -->
        <el-main class="content">
          <div v-if="isChinese" class="content-chinese">{{ contentItem.chinese }}</div>
          <el-image class="content-img" :src="contentItem.img" fit="contain" />
        </el-main>
        <!-- 字幕 -->
        <el-aside v-if="isEn && contentItem.english" width="300px" class="content" style="border-width: 0px 0px 0px 1px;  border-style:solid;border-color: rgb(237, 237, 237);">
          <div
            style=" padding: 10px;flex-wrap: wrap;display: flex;"
            @mouseleave="isEnMouse=false"
            @mouseover="isEnMouse=true"
          >
            <span v-for="(item, index) in contentItem.english" :key="index" style="cursor:pointer;font-size: 24px;font-weight: bold; ">
              <span
                v-if="item.content!==' '"
                :class="{ 'take-background':item.isSelected }"
                :style="{color:item.isHollow&&!isEnMouse? 'transparent':item.isHollow?'#c61200':'#000000',borderStyle:item.isHollow? 'solid':'none'}"
                style="border-color: #c61200;border-width: 0px 0px 2px 0px;border-style: none;"
                @mousedown="onMousedownTake(index,item)"
                @mouseover="onMouseoverTake(index,item)"
                @mouseup="onMouseupTake(item)"
              >{{ item.content }}</span>
              <span v-else :class="{ 'take-background':item.isSelected }">&nbsp;</span>
            </span>
          </div>
        </el-aside>
      </el-container>
    </div>
    <!-- 操作按钮-->
    <div class="content-but column">
      <!-- 控制区 -->
      <div class="row content" style=" width: auto;background: rgb(45, 45, 45);color: white;border-radius: 30px;padding: 5px 30px;height: 30px;margin-bottom: 5px;">
        <i class="el-icon-back" style="font-size:30px" @click="onLeft()"></i>
        <img
          v-if="isPlay"
          src="../assets/images/content/pause.png"
          style="width: 26px;margin-left: 14px;margin-right: 8px;"
          @click="onPlay"
        />
        <img
          v-else
          src="../assets/images/content/play.png"
          style="width: 30px;margin-left: 14px;margin-right: 8px;"
          @click="onPlay"
        />
        <div class="content-index">{{ contentsIndex+1 }} / {{ contents.length }}</div>
        <el-select v-model="auto" style="width: 140px;margin-left: 10px;margin-right: 10px;" @change="onAutoChange">
          <el-option label="不自动不循环" value="no" />
          <el-option label="单句循环(3秒)" value="circulation3" />
          <el-option label="单句循环(6秒)" value="circulation6" />
          <el-option label="单句循环(9秒)" value="circulation9" />
          <el-option label="自动下一个(3秒)" value="auto3" />
          <el-option label="自动下一个(6秒)" value="auto6" />
          <el-option label="自动下一个(9秒)" value="auto9" />
        </el-select>
        <span style="color: #ffbf00;">{{ keepTime }}</span>
        <svg style="margin-left: 10px;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4053" width="30" height="30" @click="onEn"><path d="M827.446642 727.549335h-80.351338v-155.002256c0-32.799081-1.717712-54.027541-5.13146-63.654674a44.548518 44.548518 0 0 0-16.758075-22.435012 47.68411 47.68411 0 0 0-27.895204-8.019601c-13.911478 0-26.358114 3.802085-37.448282 11.45683-11.057656 7.611395-18.672664 17.713563-22.742069 30.306503-4.096499 12.594746-6.142942 35.880486-6.142942 69.78136v137.56685H550.625935V423.817812h74.640081v44.620767c26.50803-34.334365 59.854395-51.468133 100.078832-51.468133 17.758718 0 33.938804 3.186166 48.614311 9.55669 14.686345 6.404843 25.812637 14.529204 33.319272 24.45075 7.542759 9.91974 12.811492 21.159824 15.73395 33.75457 2.935101 12.594746 4.436067 30.602722 4.436067 54.07089l-0.001806 188.745989zM197.680437 727.549335V308.279575h310.875109v70.910245H282.319732v92.980402h210.491026v70.659181H282.319732v114.062557h234.255414v70.657375h-318.85136z" fill="#ffffff" p-id="4054" /></svg>
        <img
          src="../assets/images/content/translate.png"
          style="width: 32px;margin-left: 8px;margin-right: 14px;"
          @click="isChinese=!isChinese;"
        />
        <i class="el-icon-right" style="font-size:30px" @click="onRight()"></i>
        <span title="全屏" style="margin-left:10px;margin-top: 5px;" @click="onFullscreen">
          <svg
            t="1632041196186"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="10154"
            width="18"
            height="18"
            class="fullscreen"
          >
            <path
              d="M398.1824 625.8176c12.288 12.288 13.4144 31.4368 3.3792 45.0048l-3.3792 3.84-217.4976 217.4976H263.168c17.5104 0 31.9488 13.0048 34.2528 29.8496l0.3072 4.7104a34.56 34.56 0 0 1-29.8496 34.2528l-4.7104 0.3072H97.28a34.56 34.56 0 0 1-34.2528-29.8496l-0.3072-4.7104v-165.888a34.56 34.56 0 0 1 68.8128-4.7104l0.3072 4.7104v82.3808l217.4976-217.3952a34.56 34.56 0 0 1 48.8448 0z m272.64-3.3792l3.84 3.3792 217.4976 217.4464v-82.432c0-17.5104 13.0048-31.9488 29.8496-34.2528l4.7104-0.3072c17.5104 0 31.9488 13.0048 34.2528 29.8496l0.3072 4.7104v165.888a34.56 34.56 0 0 1-29.8496 34.2528l-4.7104 0.3072h-165.888a34.56 34.56 0 0 1-4.7104-68.8128l4.7104-0.3072h82.432l-217.4464-217.4976a34.56 34.56 0 0 1 45.0048-52.224zM263.168 62.72a34.56 34.56 0 0 1 4.7104 68.8128l-4.7104 0.3072H180.736l217.4464 217.4976a34.56 34.56 0 0 1-45.0048 52.224l-3.84-3.3792L131.84 180.736v82.432a34.56 34.56 0 0 1-29.8496 34.2528l-4.7104 0.3072a34.56 34.56 0 0 1-34.2528-29.8496l-0.3072-4.7104V97.28c0-17.5104 13.0048-31.9488 29.8496-34.2528l4.7104-0.3072h165.888z m663.552 0c17.5104 0 31.9488 13.0048 34.2528 29.8496l0.3072 4.7104v165.888a34.56 34.56 0 0 1-68.8128 4.7104l-0.3072-4.7104V180.6848l-217.4976 217.4976a34.56 34.56 0 0 1-52.224-45.0048l3.3792-3.84 217.3952-217.4976H760.832a34.56 34.56 0 0 1-34.2528-29.8496l-0.3072-4.7104c0-17.5104 13.0048-31.9488 29.8496-34.2528l4.7104-0.3072h165.888z"
              p-id="10155"
              style="fill: white;"
            />
          </svg>
        </span>
      </div>
    </div>
    <div
      class="content-but-hint"
    >快捷键提示&nbsp;&nbsp;&nbsp;&nbsp;←:上一个&nbsp;&nbsp;&nbsp;&nbsp;→:下一个&nbsp;&nbsp;&nbsp;&nbsp;↑↓:翻译&nbsp;&nbsp;&nbsp;&nbsp;空格:播放暂停</div>
    <!-- 新有道翻译 -->
    <el-dialog
      v-dialogDrag
      :visible.sync="dialogDictionaryVisible"
      :close-on-click-modal="true"
      width="75%"
      style="max-height: 90%;"
      @close="handleDictionaryClose"
    >
      <div slot="title" class="row" style="width:100%">
        <el-input
          v-model="youdaoTitel"
          placeholder="请输入查询的词"
          @focus="onSelectWordFocus"
          @blur="onSelectWordBlur"
          @keyup.enter.native="onSelectWordEnter"
        >
          <el-button
            v-if="ModelSentenceWordList.length>1"
            slot="prepend"
            icon="el-icon-back"
            @click="onMSBack()"
          />
          <el-button slot="append" @click="onCollect">
            <img
              v-if="!isCollect"
              src="../assets/images/player/未收藏.png"
              style="width: 15px;margin-top: 5px;"
            />
            <img
              v-else
              src="../assets/images/player/已收藏.png"
              style="width: 15px;margin-top: 5px;"
            />
          </el-button>
        </el-input>
        <el-button-group style="width:400px;margin-left: 10px;margin-right: 30px;">
          <el-button
            type="success"
            icon="el-icon-search"
            @click="onSelectWordEnter"
          >查词</el-button>
          <el-button
            v-if="jurisdiction.functions['ess']"
            type="primary"
            icon="el-icon-tickets"
            @click="onModelSentence"
          >例句</el-button>
          <el-button
            v-if="jurisdiction.functions['qjsp']"
            icon="el-icon-tickets"
            @click="onSceneVideo"
          >情景视频</el-button>
        </el-button-group>
      </div>
      <el-scrollbar
        style="width:100%;height:100%;"
      >
        <!-- 有道翻译 -->
        <div v-if="isModelSentence==1" class="column">
          <div class="row">
            <el-button :loading="aiExplainLogin" @click="htmlType=0;">Ai</el-button>
            <el-button @click="htmlType=2;">中文</el-button>
            <el-button v-if="meiYinVideo!=null" @click="htmlType=3;">发音</el-button>
            <el-button :loading="klsLoading" @click="htmlType=1;onKls()">英英</el-button>
            <el-select v-model="htmlType" style="margin-left: 10px;" @change="onHtmlType">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div v-if="htmlType==0" :style="{fontSize: youdaoTextSize+12 +'px'}" style="margin-top:10px;">
            <div v-html="aiExplainText"></div>
            <div style="background: black;position: absolute;right: 0px;padding: 14px 0px;border-radius: 10px;top:10px;">
              <el-slider v-model="youdaoTextSize" :max="20" :vertical="true" height="100px" @change="onYoudaoTextSize" />
            </div>
          </div>
          <div v-if="htmlType===2" class="column" style="margin-top:10px;">
            <div style="background: black;position: absolute;right: 0px;padding: 14px 0px;border-radius: 10px;top:10px;">
              <el-slider v-model="youdaoTextSize" :max="20" :vertical="true" height="100px" @change="onYoudaoTextSize" />
            </div>
            <!-- 单词 -->
            <div v-if="translate.dict">
              <!-- 原语言 -->
              <div class="row vertical-center">
                <span v-if="translate.dict.base" style="font-weight: bold;margin-left:5px; margin-right: 10px;" :style="{fontSize: youdaoTextSize+ 24 +'px'}">{{ translate.dict.base.word }}</span>
                <svg t="1714106921932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4348" width="14" height="14" @click="onKeLinShiAudio(translate.dict.base.pronounce)"><path d="M148.00896 365.010944l0 0.41984c-1.527808-0.256-3.084288-0.421888-4.685824-0.421888L53.37088 365.008896c-15.525888 0-28.110848 12.58496-28.110848 28.110848l0 264.2432c0 15.521792 12.587008 28.110848 28.110848 28.110848l89.952256 0c1.599488 0 3.158016-0.16384 4.685824-0.421888l0 0.41984 327.95648 227.69664L475.96544 131.692544 148.00896 365.010944z" fill="#222222" p-id="4349" /><path d="M654.938112 185.229312l0 33.755136c170.579968 4.95616 307.339264 144.78336 307.339264 316.563456 0 171.782144-136.759296 311.605248-307.339264 316.565504l0 33.75104c189.210624-4.9664 341.07392-159.905792 341.07392-350.318592S844.148736 190.19776 654.938112 185.229312z" fill="#222222" p-id="4350" /><path d="M624.664576 308.789248l0 21.848064c110.41792 3.209216 198.940672 93.71648 198.940672 204.910592 0 111.192064-88.522752 201.701376-198.940672 204.910592l0 21.85216c122.476544-3.213312 220.7744-103.50592 220.7744-226.762752C845.438976 412.295168 747.139072 312.004608 624.664576 308.789248z" fill="#222222" p-id="4351" /><path d="M576.223232 395.034624l0 13.53728c68.421632 1.990656 123.275264 58.073088 123.275264 126.976s-54.853632 124.987392-123.275264 126.976l0 13.539328c75.894784-1.994752 136.808448-64.141312 136.808448-140.515328S652.118016 397.02528 576.223232 395.034624z" fill="#222222" p-id="4352" /></svg>
              </div>
              <!-- 英标 -->
              <div class="row" style="margin-top: 10px;">
                <span v-if="translate.dict.base.pron_am" style="background: #eaeaea;padding: 5px 15px;border-radius: 20px;margin-right: 10px;" :style="{fontSize: youdaoTextSize +12 +'px'}">英[{{ translate.dict.base.pron_am.text }}]
                  <svg t="1714106921932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4348" width="14" height="14" @click="aliPay('olivia')"><path d="M148.00896 365.010944l0 0.41984c-1.527808-0.256-3.084288-0.421888-4.685824-0.421888L53.37088 365.008896c-15.525888 0-28.110848 12.58496-28.110848 28.110848l0 264.2432c0 15.521792 12.587008 28.110848 28.110848 28.110848l89.952256 0c1.599488 0 3.158016-0.16384 4.685824-0.421888l0 0.41984 327.95648 227.69664L475.96544 131.692544 148.00896 365.010944z" fill="#222222" p-id="4349" /><path d="M654.938112 185.229312l0 33.755136c170.579968 4.95616 307.339264 144.78336 307.339264 316.563456 0 171.782144-136.759296 311.605248-307.339264 316.565504l0 33.75104c189.210624-4.9664 341.07392-159.905792 341.07392-350.318592S844.148736 190.19776 654.938112 185.229312z" fill="#222222" p-id="4350" /><path d="M624.664576 308.789248l0 21.848064c110.41792 3.209216 198.940672 93.71648 198.940672 204.910592 0 111.192064-88.522752 201.701376-198.940672 204.910592l0 21.85216c122.476544-3.213312 220.7744-103.50592 220.7744-226.762752C845.438976 412.295168 747.139072 312.004608 624.664576 308.789248z" fill="#222222" p-id="4351" /><path d="M576.223232 395.034624l0 13.53728c68.421632 1.990656 123.275264 58.073088 123.275264 126.976s-54.853632 124.987392-123.275264 126.976l0 13.539328c75.894784-1.994752 136.808448-64.141312 136.808448-140.515328S652.118016 397.02528 576.223232 395.034624z" fill="#222222" p-id="4352" /></svg>
                </span>
                <span v-if="translate.dict.base.pron_en" style="background: #eaeaea;padding: 5px 15px;border-radius: 20px;" :style="{fontSize: youdaoTextSize+12 +'px'}">美[{{ translate.dict.base.pron_en.text }}]
                  <svg t="1714106921932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4348" width="14" height="14" @click="aliPay('cindy')"><path d="M148.00896 365.010944l0 0.41984c-1.527808-0.256-3.084288-0.421888-4.685824-0.421888L53.37088 365.008896c-15.525888 0-28.110848 12.58496-28.110848 28.110848l0 264.2432c0 15.521792 12.587008 28.110848 28.110848 28.110848l89.952256 0c1.599488 0 3.158016-0.16384 4.685824-0.421888l0 0.41984 327.95648 227.69664L475.96544 131.692544 148.00896 365.010944z" fill="#222222" p-id="4349" /><path d="M654.938112 185.229312l0 33.755136c170.579968 4.95616 307.339264 144.78336 307.339264 316.563456 0 171.782144-136.759296 311.605248-307.339264 316.565504l0 33.75104c189.210624-4.9664 341.07392-159.905792 341.07392-350.318592S844.148736 190.19776 654.938112 185.229312z" fill="#222222" p-id="4350" /><path d="M624.664576 308.789248l0 21.848064c110.41792 3.209216 198.940672 93.71648 198.940672 204.910592 0 111.192064-88.522752 201.701376-198.940672 204.910592l0 21.85216c122.476544-3.213312 220.7744-103.50592 220.7744-226.762752C845.438976 412.295168 747.139072 312.004608 624.664576 308.789248z" fill="#222222" p-id="4351" /><path d="M576.223232 395.034624l0 13.53728c68.421632 1.990656 123.275264 58.073088 123.275264 126.976s-54.853632 124.987392-123.275264 126.976l0 13.539328c75.894784-1.994752 136.808448-64.141312 136.808448-140.515328S652.118016 397.02528 576.223232 395.034624z" fill="#222222" p-id="4352" /></svg>
                </span>
              </div>
              <!-- 释义 -->
              <div class="column" style="margin-top: 15px;">
                <span style="font-weight: bold;margin-top: 5px;" :style="{fontSize: youdaoTextSize+16 +'px'}">释义</span>
                <div class="column" style="margin-top: 5px" :style="{fontSize: youdaoTextSize+14 +'px'}">
                  <div v-for="(item, index) in translate.dict.base.explain.word_means" :key="index">
                    <span style="color: #909399;">{{ item.part }} </span>
                    <span v-for="(m, mi) in item.means" :key="mi" style="margin-left: 10px;">{{ m }} </span>
                  </div>
                </div>
              </div>
              <!-- 词态变化 -->
              <div v-if="translate.dict.base.exchange.word_done" class="column" style="margin-top: 15px;">
                <span style="font-weight: bold;margin-top: 5px;" :style="{fontSize: youdaoTextSize+16 +'px'}">词态变化</span>
                <div class="row" style="margin-top: 5px" :style="{fontSize: youdaoTextSize+14 +'px'}">
                  <div v-if="translate.dict.base.exchange.word_done.length>0" style="margin-right: 10px;">
                    <span style="color: #909399;">过去分词:</span>
                    <span v-for="(m, mi) in translate.dict.base.exchange.word_done" :key="mi">{{ m }} </span>
                  </div>
                  <div v-if="translate.dict.base.exchange.word_ing.length>0" style="margin-right: 10px;">
                    <span style="color: #909399;">现代分词:</span>
                    <span v-for="(m, mi) in translate.dict.base.exchange.word_ing" :key="mi">{{ m }} </span>
                  </div>
                  <div v-if="translate.dict.base.exchange.word_past.length>0" style="margin-right: 10px;">
                    <span style="color: #909399;">过去式:</span>
                    <span v-for="(m, mi) in translate.dict.base.exchange.word_past" :key="mi">{{ m }} </span>
                  </div>
                  <div v-if="translate.dict.base.exchange.word_pl.length>0" style="margin-right: 10px;">
                    <span style="color: #909399;">复数:</span>
                    <span v-for="(m, mi) in translate.dict.base.exchange.word_pl" :key="mi">{{ m }} </span>
                  </div>
                  <div v-if="translate.dict.base.exchange.word_third.length>0" style="margin-right: 10px;">
                    <span style="color: #909399;">第三人称单数:</span>
                    <span v-for="(m, mi) in translate.dict.base.exchange.word_third" :key="mi">{{ m }} </span>
                  </div>
                </div>
              </div>
              <!-- 真题例句 -->
              <div class="column" style="margin-top: 15px;">
                <span style="font-weight: bold;margin-top: 5px;" :style="{fontSize: youdaoTextSize+16 +'px'}">真题例句</span>
                <div v-for="(ts, tsi) in translate.dict.true_sentences" :key="tsi">
                  <div v-for="(sentences, si) in ts.sentences" :key="si" class="column" style="margin-bottom: 10px;">
                    <span :style="{fontSize: youdaoTextSize+14 +'px'}">{{ sentences.text }}</span>
                    <span style="color: #909399;" :style="{fontSize: youdaoTextSize+12 +'px'}">{{ sentences.source }}</span>
                  </div>
                </div>
              </div>
              <!-- 同义词 -->
              <div class="column" style="margin-top: 15px;">
                <span style="font-weight: bold;margin-top: 5px;" :style="{fontSize: youdaoTextSize+16 +'px'}">同义词</span>
                <div v-for="(s, si) in translate.dict.synonym" :key="si" class="column" style="margin-top: 5px;">
                  <div v-for="(wm, wmi) in s.word_means" :key="wmi" class="column" style="margin-bottom: 10px;">
                    <div>
                      <span style="font-weight: bold;" :style="{fontSize: youdaoTextSize+14 +'px'}">{{ s.part }}</span>
                      <span style="font-weight: bold;" :style="{fontSize: youdaoTextSize+14 +'px'}">{{ wm.mean }}</span>
                    </div>
                    <div class="row" style="flex-wrap: wrap;"><span v-for="(w,wi) in wm.words" :key="wi" style="margin-right:10px;" :style="{fontSize: youdaoTextSize+12 +'px'}">{{ w }}</span></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 文本翻译 -->
            <div v-if="translate.transResult">
              <span style="font-weight: bold;margin-left:5px; margin-right: 10px;" :style="{fontSize: youdaoTextSize+ 18 +'px'}">{{ translate.transResult }}</span>
            </div>
          </div>
          <div v-if="htmlType===3" style="margin-top:10px;">
            <video
              id="sceneVideo"
              ref="sceneVideo"
              oncontextmenu="return false;"
              style="outline: none;width: 100%;height: 100%;border-radius: 6px;"
              :src="meiYinVideo"
              controlslist="nodownload"
              controls
              @click.left="onsSceneVideoClickLeft"
              @timeupdate="onTimeupdate"
            ></video>
          </div>
          <div v-if="htmlType===1" style="margin-top:10px;" v-html="klsHtml"></div>
        </div>
        <!-- 例句 -->
        <div v-if="isModelSentence==2">
          <div
            v-if="msLogin"
            style="font-size: 10px;color: #777;margin: 20px 0px;"
          >检索例句中...</div>
          <el-scrollbar
            v-else
            class="scrollbar"
            style="height: 100%;width: 100%;"
          >
            <div style="background: black;position: absolute;right: 0px;padding: 14px 0px;border-radius: 10px;top:10px;">
              <el-slider v-model="youdaoTextSize" :max="20" :vertical="true" height="100px" @change="onYoudaoTextSize" />
            </div>
            <div
              v-for="(item, index) in PageModelSentenceList"
              :key="index"
              class="column ms-row"
              style="cursor:pointer;"
              @click.stop="osMSOpacity(PageModelSentenceIndex*20+ index)"
            >
              <span class="ms-english">
                <span>{{ PageModelSentenceIndex*20+ index+1 }}.</span>
                <div
                  v-if="((item.frequency0>0 || item.frequency1>0) && item.frequency2==0 && item.frequency3==0 && item.frequency4==0 && item.frequency5==0)"
                  class="frequency"
                  style="background: #EEA531;"
                >初级</div>
                <div
                  v-if="((item.frequency0>0 || item.frequency1>0 ) && item.frequency2>0 && item.frequency3==0 && item.frequency4==0 && item.frequency5==0)"
                  class="frequency"
                  style="background: #3D8D2B;"
                >初中级</div>
                <div
                  v-if="((item.frequency2>0 || item.frequency3>0) && item.frequency4==0 && item.frequency5==0)"
                  class="frequency"
                  style="background: #2FA2D2;"
                >中级</div>
                <div
                  v-if="((item.frequency2>0 || item.frequency3>0 ) && item.frequency4>0 && item.frequency5==0)"
                  class="frequency"
                  style="background: #2E63C3;"
                >中高级</div>
                <div
                  v-if="((item.frequency4>0 || item.frequency5>0))"
                  class="frequency"
                  style="background: #9D24B3;"
                >高级</div>
                <span
                  style="max-width: 580px;-webkit-touch-callout: text;-webkit-user-select: text; -khtml-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text;margin-left: 5px;"
                  :style="{fontSize: youdaoTextSize+18 +'px'}"
                  v-html="item.english"
                ></span>
                <div
                  class="play"
                  style="background: #5961cd;margin-left: 20px;"
                  @click.stop="onMsPlay(1,item.englishText)"
                >
                  <img src="../assets/images/home/播放按钮.png" />
                </div>
                <div
                  class="play"
                  style="background: #B62B1A;margin-left: 10px;"
                  @click.stop="onMsPlay(2,item)"
                >
                  <img src="../assets/images/home/播放按钮.png" />
                </div>
                <i
                  v-if="item.isMsLoading"
                  class="el-icon-loading"
                  style="margin-left:5px;"
                ></i>
              </span>
              <span class="ms-chinese">
                <span :style="{fontSize: youdaoTextSize+12 +'px'}" v-html="item.chinese"></span>
              </span>
              <span class="ms-provenance" :style="{fontSize: youdaoTextSize+10 +'px'}">来自:{{ item.grouping }}({{ item.name }})</span>
            </div>
          </el-scrollbar>
          <div
            v-if="ModelSentenceList.length>0"
            class="row"
            style="position:absolute;bottom: 20px;right: 0px;font-size: 12px;z-index: 9999;"
          >
            <label
              v-if="ModelSentenceList.length>=1"
              :style="{'font-weight':PageModelSentenceIndex===0 ? 'bold' : ''}"
              style="cursor:pointer;"
              @click="onModelSentenceListPage(1)"
            >&nbsp;第1页&nbsp;</label>
            <label
              v-if="ModelSentenceList.length>=20"
              :style="{'font-weight':PageModelSentenceIndex===1 ? 'bold' : ''}"
              style="cursor:pointer;"
              @click="onModelSentenceListPage(2)"
            >&nbsp;第2页&nbsp;</label>
            <label
              v-if="ModelSentenceList.length>=40"
              :style="{'font-weight':PageModelSentenceIndex===2 ? 'bold' : ''}"
              style="cursor:pointer;"
              @click="onModelSentenceListPage(3)"
            >&nbsp;第3页&nbsp;</label>
            <label
              v-if="ModelSentenceList.length>=60"
              :style="{'font-weight':PageModelSentenceIndex===3 ? 'bold' : ''}"
              style="cursor:pointer;"
              @click="onModelSentenceListPage(4)"
            >&nbsp;第4页&nbsp;</label>
            <label
              v-if="ModelSentenceList.length>=80"
              :style="{'font-weight':PageModelSentenceIndex===4 ? 'bold' : ''}"
              style="cursor:pointer;"
              @click="onModelSentenceListPage(5)"
            >&nbsp;第5页&nbsp;</label>
          </div>
        </div>
        <!-- 情景视频 -->
        <div v-if="isModelSentence == 3" style="width: 100%;margin-top: 5px;height: 100%;" class="row">
          <div style="width: 200px;margin-right: 10px;padding-bottom: 10px;height:100%;" class="column">
            <el-scrollbar style="height:100%;">
              <div class="row" style="flex-wrap: wrap;">
                <div
                  v-for="(item,i) in scenedim"
                  :key="item.md5"
                  class="column"
                  style="margin: 5px;border-radius: 30px;font-weight: bold;color: white;border: solid 1px #e8e8e8;"
                  :style="{'background':(item.grouping==='10003'?'#000000': backgroundColor[i%4])}"
                  @click="onDim(item)"
                >
                  <!-- <el-image :src="item.cover" fit="cover" class="item-img" lazy style="height: 100px;border-radius: 6px 6px 0px 0px;" /> -->
                  <label style="font-size: 12px;padding: 10px;">{{ item.name }}</label>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="video column" style="width: 100%;height: 100%;position: relative;background: black;">
            <video
              id="sceneVideo"
              ref="sceneVideo"
              oncontextmenu="return false;"
              style="outline: none;width: 100%;height: 100%;border-radius: 6px;"
              :src="sceneVideoUrl"
              controlslist="nodownload"
              controls
              @click.left="onsSceneVideoClickLeft"
              @timeupdate="onTimeupdate"
            ></video>
            <!-- 字幕 -->
            <div class="column" style="position: absolute;bottom: 30px;color: white;width: 100%;display: flex;justify-content: center;align-items: center;">
              <div v-if="subtitle && subtitle.english" style="background: #000000c2;padding: 5px 10px;border-radius: 8px;font-size: 16px;font-weight: bold;"> <label>{{ subtitle.english }}</label></div>
              <div v-if="subtitle && subtitle.chinese" style="background: #000000c2;padding: 5px 10px;border-radius: 8px;font-size: 14px;margin-top: 5px;"> <label>{{ subtitle.chinese }}</label></div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import {
  cartoonGrouping,
  bookCataList,
  cartoonBookContent,
  dowJur,
  deBook
} from '../apis/cartoon'
import {
  addWord, getAvName
} from '@/apis/video'
import {
  modelSentence,
  getSubtitle
} from '@/apis/subtitle'
import { fygTranslate, kls } from '@/apis/sys'
import { search, speechSynthesisToken } from '@/apis/ai'
import { isCollect, addHollow, hollowList, deHollow } from '@/apis/word-library'
import SparkMD5 from 'spark-md5'

export default {
  components: {},
  props: {},
  data() {
    return {
      jurisdiction: {},
      isScreen: false,
      audio: {}, // 音频播放
      groupingName: '',
      bookName: '',
      type: 1, // 1:绘本分组  2:书本列表  3:书本内容
      groupings: [],
      books: [],
      contents: [],
      contentItem: {},
      contentsIndex: 0,
      isPlay: false,
      isChinese: false,
      auto: 'no',
      keepTime: 0,
      takeButtonsType: 1,
      interval: null,
      isSelectedInedx: -1,
      isSelectedTake: false,
      dialogDictionaryVisible: false, // 是否显示词典弹出框
      youdaoTitel: '', // 有道翻译标题
      isCollect: false,
      msLogin: true, // 例句检索中
      isModelSentence: 0, // 滑词例句
      ModelSentenceList: [], // 例句总集合
      PageModelSentenceIndex: 0, // 例句分页索引
      PageModelSentenceList: [], // 例句分页集合
      ModelSentenceWordList: [], // 例句搜索记录
      youdaoTextSize: 0, // 有道翻译字体增加大小
      youDaoSize: { // 有道翻译窗口大小
        width: 0,
        height: 0
      },
      scenedim: [],
      sceneVideoUrl: '', // 情景视频播放地址
      subtitle: [],
      backgroundColor: ['#f87622', '#30b405', '#d74104', '#0496d7'], // 情景视频相关视频背景色
      translate: {
        basic: []
      }, // 有道翻译数据
      isEn: false,
      allHollowList: [], // 全局挖空数据
      isEnMouse: false,
      isDowJur: false, // 上传绘本处理中
      showDeDialog: false,
      deCartoonId: '',
      htmlType: 2, // 翻译类型
      klsHtml: '', // 柯林斯翻译HTML
      klsLoading: false, // 柯林斯英英词典

      aiExplainLogin: false,
      aiExplainText: '', // Ai释义
      options: [{
        value: 0,
        label: '默认翻译 Ai'
      }, {
        value: 2,
        label: '默认翻译 中文'
      }]
    }
  },
  created() {
    // 柯林斯发音（window事件转化到vue事件）
    window.onKeLinShiAudio = this.onKeLinShiAudio
    // Ai释义发音（window事件转化到vue事件）
    window.onPayAi = this.onPayAi
    cartoonGrouping().then(res => {
      this.groupings = res.data
    })
    this.jurisdiction = JSON.parse(localStorage.getItem('user')).jurisdiction
    this.youDaoSize.width = document.documentElement.clientWidth
    this.youDaoSize.height = document.documentElement.clientHeight
    if (localStorage.getItem('HtmlType')) { this.htmlType = parseInt(localStorage.getItem('HtmlType')) } else { this.htmlType = 0 }
  },
  beforeRouteLeave(to, form, next) {
    this.audio.pause()
    next()
  },
  mounted() {
    var that = this
    this.audio = new Audio()
    // 播放结束
    this.audio.addEventListener(
      'ended',
      function() {
        that.audio.pause()
        if (that.auto !== 'no') {
          that.interval = setInterval(function() {
            that.keepTime -= 1
            if (that.keepTime < 0) {
              clearInterval(that.interval)
              switch (that.auto) {
                case 'circulation3':
                  that.audio.play()
                  that.keepTime = 3
                  break
                case 'circulation6':
                  that.audio.play()
                  that.keepTime = 6
                  break
                case 'circulation9':
                  that.audio.play()
                  that.keepTime = 9
                  break
                case 'auto3':
                  that.onRight()
                  that.keepTime = 3
                  break
                case 'auto6':
                  that.onRight()
                  that.keepTime = 6
                  break
                case 'auto9':
                  that.onRight()
                  that.keepTime = 9
                  break
                case 'no':
                  that.keepTime = 0
                  break
              }
            }
          }, 1000)
        }
      },
      false
    )
    // 开始播放
    this.audio.addEventListener(
      'play',
      function() {
        that.isPlay = true
      },
      false
    )
    // 暂停播放
    this.audio.addEventListener(
      'pause',
      function() {
        that.isPlay = false
        clearInterval(that.interval)
      },
      false
    )
    document.onkeydown = function({ code }) {
      if (that.type === 3) {
        switch (code.toLowerCase()) {
          case 'arrowright':
            that.onRight()
            break
          case 'arrowleft':
            that.onLeft()
            break
          case 'space':
            that.onPlay()
            break
          case 'arrowup':
          case 'arrowdown':
            that.isChinese = !that.isChinese
            break
        }
      }
    }
    that.auto = localStorage.getItem('cartoonauto') === null ? 'no' : localStorage.getItem('cartoonauto')

    if (localStorage.getItem('youdaoTextSize') != null) {
      that.youdaoTextSize = Number(localStorage.getItem('youdaoTextSize'))
    }
    // 获取全局挖空列表
    hollowList().then(res => {
      this.allHollowList = res.data
    })
  },
  methods: {
    // 分组点击
    onGrouping(e) {
      this.type = 2
      bookCataList({ gid: e }).then(res => {
        this.books = res.data
      })
    },
    // 加载绘本内容
    onBook(e) {
      this.type = 3
      cartoonBookContent({ bid: e }).then(res => {
        this.contentsIndex = 0
        res.data.forEach(ele => {
          if (ele.english) {
            ele.english = ele.english.replace('\n', '')
            var ens = []
            ele.english.split(/\b/).forEach(en => {
              ens.push({
                content: en,
                isSelected: false,
                isHollow: this.allHollowList.filter((item, index) => item.word.trim().toLowerCase() === en.trim().toLowerCase()).length > 0
              })
            })
            ele.english = ens
          }
        })
        this.contents = res.data
        this.contentItem = res.data[0]
        this.audio.src = this.contentItem.audio
        this.audio.play()
      })
    },
    // 左
    onLeft() {
      if (this.contentsIndex === 0) return
      this.audio.pause()
      this.isChinese = false
      // this.audio.stop()
      this.contentsIndex -= 1
      this.contentItem = this.contents[this.contentsIndex]
      this.audio.src = this.contentItem.audio
      this.audio.play()
    },
    // 右
    onRight() {
      if (this.contentsIndex === this.contents.length - 1) return
      this.audio.pause()
      this.isChinese = false
      // this.audio.stop()
      this.contentsIndex += 1
      this.contentItem = this.contents[this.contentsIndex]
      this.audio.src = this.contentItem.audio
      this.audio.play()
    },
    // 播放
    onPlay() {
      if (this.isPlay) this.audio.pause()
      else this.audio.play()
    },
    // 全屏事件
    onFullscreen() {
      this.isScreen = !this.isScreen
      Vue.$screen()
      if (this.isScreen) this.launchFullscreen(document.documentElement)
      else this.exitFullscreen()
    },
    // 全屏
    launchFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('videobox')
        var cssText = 'width:100%;height:100%;overflow:hidden;'
        docHtml.style.cssText = cssText
        docBody.style.cssText = cssText
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    },
    // 退出全屏
    exitFullscreen() {
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) return
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('videobox')
        docHtml.style.cssText = ''
        docBody.style.cssText = ''
        videobox.style.cssText = ''
        document.IsFullScreen = false
        isFull.call(document)
      }
    },
    // 返回时退出全屏
    onBack() {
      this.keepTime = 0
      clearInterval(this.interval)
      if (this.isScreen) this.onFullscreen()
    },
    onAutoChange(e) {
      var that = this
      switch (that.auto) {
        case 'circulation3':
        case 'auto3':
          this.keepTime = 3
          if (!this.isPlay) that.audio.play()
          break
        case 'circulation6':
        case 'auto6':
          this.keepTime = 6
          if (!this.isPlay) that.audio.play()
          break
        case 'circulation9':
        case 'auto9':
          this.keepTime = 9
          if (!this.isPlay) that.audio.play()
          break
        case 'no':
          break
      }
      localStorage.setItem('cartoonauto', e)
    },
    // 单词鼠标按下事件
    onMousedownTake(index, obj) {
      this.takeButtonsType = event.buttons
      for (let i = 0; i < this.contentItem.english.length; i++) {
        this.contentItem.english[i].isSelected = false
      }
      this.isSelectedInedx = index
      this.isSelectedTake = true
      obj.isSelected = true
    },
    // 单词鼠标移入事件
    onMouseoverTake(index, obj) {
      if (!this.isSelectedTake) {
        return
      }
      if (this.takeButtonsType !== 1) return
      // 滑词逻辑
      if (this.isSelectedInedx <= index) {
        // 右滑
        for (let i = 0; i < this.contentItem.english.length; i++) {
          // 标记选择
          if (i >= this.isSelectedInedx && i <= index) {
            this.contentItem.english[i].isSelected = true
          } else {
            // 取消选择
            this.contentItem.english[i].isSelected = false
          }
        }
      } else {
        // 左滑
        for (let i = 0; i < this.contentItem.english.length; i++) {
          if (i <= this.isSelectedInedx && i >= index) {
            this.contentItem.english[i].isSelected = true
          } else {
            this.contentItem.english[i].isSelected = false
          }
        }
      }
    },
    // 单词鼠标松开事件
    onMouseupTake(item) {
      this.isModelSentence = 1
      this.isSelectedTake = false
      // 处理单词
      var selectedEl = this.contentItem.english.filter((item, index) => item.isSelected === true)
      if (selectedEl.length === 0 && this.isSelectedInedx >= 0) {
        selectedEl = [this.contentItem.english[this.isSelectedInedx]]
      } else if (selectedEl.length === 0 && this.isSelectedInedx < 0) return
      var paragraph = Array.from(selectedEl, x => x.content).join('')
      // 删除特殊字符
      paragraph = paragraph.replace(/\,/g, ' ')
      paragraph = paragraph.replace(/\./g, ' ')
      paragraph = paragraph.replace(/\?/g, ' ')
      paragraph = paragraph.replace(/\!/g, ' ')
      this.youdaoTitel = paragraph
      if (this.takeButtonsType === 1) this.onLoginYoudao()
      if (this.takeButtonsType === 2 && selectedEl.length === 1) {
        item.isHollow = !item.isHollow
        var isWord = this.allHollowList.filter((x, index) => {
          if (x.word?.toLowerCase() === item.content.trim().toLowerCase()) {
            this.allHollowList.splice(index, 1)
            return x
          }
        })
        if (isWord.length > 0) {
          deHollow({ hid: isWord[0].id })
        } else {
          addHollow({ word: item.content.trim() }).then(res => {
            this.allHollowList.push({ word: item.content.trim(), id: res.data })
          })
        }
        this.contentItem.english.forEach(function(el, index) {
          el.isSelected = false
        })
      }
    },
    // 有道词典关闭
    handleDictionaryClose() {
      this.dialogDictionaryVisible = false
      window.getSelection().empty()
      this.aiExplainText = ''
      if (localStorage.getItem('HtmlType')) { this.htmlType = parseInt(localStorage.getItem('HtmlType')) } else { this.htmlType = 0 }
      this.contentItem.english.forEach(function(el, index) {
        el.isSelected = false
      })
      this.$refs.sceneVideo?.pause()
    },
    // 有道查词 获取焦点
    onSelectWordFocus() {
      document.onkeydown = {}
    },
    // 有道查词 失去焦点
    onSelectWordBlur() {
      var that = this
      document.onkeydown = function({ code }) {
        if (that.type === 3) {
          switch (code.toLowerCase()) {
            case 'arrowright':
              that.onRight()
              break
            case 'arrowleft':
              that.onLeft()
              break
            case 'space':
              that.onPlay()
              break
            case 'arrowup':
            case 'arrowdown':
              that.isChinese = !that.isChinese
              break
          }
        }
      }
    },
    // 查词
    onSelectWordEnter() {
      this.isModelSentence = 1
      this.onLoginYoudao()
    },
    // 加载有道翻译
    onLoginYoudao() {
      this.dialogDictionaryVisible = true
      this.audio.src = `http://dict.youdao.com/speech?audio=${this.youdaoTitel}`
      this.audio.play()
      this.translate = {}
      search({ word: this.youdaoTitel.trim() }).then(res => {
        this.aiExplainText = ''
        this.aiExplainTexts = res.data.text.split('\n')
        res.data.text.split('\n').forEach((item, index) => {
          if (item !== '') {
            var payAi = `<svg onclick="onPayAi(${index})"  data-v-236478fb="" t="1714106921932" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4348" width="14" height="14" class="icon"><path data-v-236478fb="" d="M148.00896 365.010944l0 0.41984c-1.527808-0.256-3.084288-0.421888-4.685824-0.421888L53.37088 365.008896c-15.525888 0-28.110848 12.58496-28.110848 28.110848l0 264.2432c0 15.521792 12.587008 28.110848 28.110848 28.110848l89.952256 0c1.599488 0 3.158016-0.16384 4.685824-0.421888l0 0.41984 327.95648 227.69664L475.96544 131.692544 148.00896 365.010944z" fill="#222222" p-id="4349"></path><path data-v-236478fb="" d="M654.938112 185.229312l0 33.755136c170.579968 4.95616 307.339264 144.78336 307.339264 316.563456 0 171.782144-136.759296 311.605248-307.339264 316.565504l0 33.75104c189.210624-4.9664 341.07392-159.905792 341.07392-350.318592S844.148736 190.19776 654.938112 185.229312z" fill="#222222" p-id="4350"></path><path data-v-236478fb="" d="M624.664576 308.789248l0 21.848064c110.41792 3.209216 198.940672 93.71648 198.940672 204.910592 0 111.192064-88.522752 201.701376-198.940672 204.910592l0 21.85216c122.476544-3.213312 220.7744-103.50592 220.7744-226.762752C845.438976 412.295168 747.139072 312.004608 624.664576 308.789248z" fill="#222222" p-id="4351"></path><path data-v-236478fb="" d="M576.223232 395.034624l0 13.53728c68.421632 1.990656 123.275264 58.073088 123.275264 126.976s-54.853632 124.987392-123.275264 126.976l0 13.539328c75.894784-1.994752 136.808448-64.141312 136.808448-140.515328S652.118016 397.02528 576.223232 395.034624z" fill="#222222" p-id="4352"></path></svg>`
            this.aiExplainText += item + payAi + '<br>'
          }
        })
        this.aiExplainLogin = false
      })
      fygTranslate({ word: this.youdaoTitel.trim(), uid: this.uid, isApi: true }).then(res => {
        this.translate = res.data
      })
      isCollect({ word: this.youdaoTitel }).then(res => {
        this.isCollect = res.data
      })
    },
    // 收藏单词
    onCollect() {
      addWord({
        word: this.youdaoTitel,
        type: 1,
        en: '',
        cn: '',
        subtitle: null
      }).then(res => {
        this.isCollect = true
      })
    },
    // 搜索字幕例句
    onModelSentence() {
      var that = this
      this.isModelSentence = 2
      this.msLogin = true
      // 例句关键词搜索记录
      if (
        that.ModelSentenceWordList.length === 0 ||
        that.ModelSentenceWordList[that.ModelSentenceWordList.length - 1] !==
          this.youdaoTitel.trim()
      ) {
        that.ModelSentenceWordList.push(this.youdaoTitel.trim())
      }
      var example = localStorage.getItem('example')
      var zhOpacity = 0
      var enOpacity = 0
      if (example) {
        switch (example) {
          case '1':
            zhOpacity = 1
            enOpacity = 1
            break
          case '2':
            zhOpacity = 0
            enOpacity = 1
            break
          case '3':
            zhOpacity = 1
            enOpacity = 0
            break
          case '4':
            zhOpacity = 0
            enOpacity = 0
            break
        }
      }
      // 查询条件/排序
      var level = localStorage.getItem('level')
      var sort = localStorage.getItem('sort')
      var where = '1=1'
      if (level) {
        level = JSON.parse(level)
        if (level.length < 5 && level.length !== 0) {
          level.forEach(x => {
            if (where === '1=1') {
              where = `${where} and (`
            } else {
              where = `${where} or`
            }
            switch (x) {
              case '初级':
                where = `${where} ((t1.Frequency0>0 or t1.Frequency1>0) and t1.Frequency2=0 and t1.Frequency3=0 and t1.Frequency4=0 and t1.Frequency5=0)`
                break
              case '初中级':
                where = `${where} ((t1.Frequency0>0 or t1.Frequency1>0 ) and t1.Frequency2>0 and t1.Frequency3=0 and t1.Frequency4=0 and t1.Frequency5=0)`
                break
              case '中级':
                where = `${where} ((t1.Frequency2>0 or t1.Frequency3>0) and t1.Frequency4=0 and t1.Frequency5=0)`
                break
              case '中高级':
                where = `${where} ((t1.Frequency2>0 or t1.Frequency3>0 ) and t1.Frequency4>0 and t1.Frequency5=0)`
                break
              case '高级':
                where = `${where} ((t1.Frequency4>0 or t1.Frequency5>0))`
                break
            }
          })
          where = `${where} )`
        } else {
          where = ''
        }
      }
      if (sort) {
        sort = JSON.parse(sort)
        var orderBy = ''
        if (sort.length > 0) {
          if (where === '') {
            where = '1=1'
          }
          sort.forEach(x => {
            switch (x) {
              case '学习时间':
                orderBy = `${orderBy} t5.LearningTime DESC,`
                break
              case '例句长度':
                orderBy = `${orderBy} if(ISNULL(t1.Length),10000,t1.Length),`
                // where = `${where} and t1.Length>0`
                break
              case '复杂程度':
                orderBy = `${orderBy} if(ISNULL(t1.MaxLevel),7,t1.MaxLevel),`
                // where = `${where} and t1.MinLevel>0`
                break
            }
          })
          orderBy = orderBy.substring(0, orderBy.lastIndexOf(','))
        }
      }
      // 查询例句
      modelSentence({
        word: this.youdaoTitel.trim(),
        orderBy: orderBy,
        where: where
      }).then(res => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index]
          element.englishText = element.english
          that.youdaoTitel = that.youdaoTitel.trim()
          var symbol = that.youdaoTitel.substring(that.youdaoTitel.length - 1)
          if (
            symbol === '.' ||
            symbol === ',' ||
            symbol === '?' ||
            symbol === '!' ||
            symbol === '！' ||
            symbol === '？'
          ) {
            that.youdaoTitel = that.youdaoTitel.substring(
              0,
              that.youdaoTitel.length - 1
            )
          }
          var reg = new RegExp(`\\b${that.youdaoTitel}\\b`, 'i', 'g')
          element.english = element.english.replace(
            reg,
            `<span style="color: #00a803;opacity: 0;" id="msword${index}">${that.youdaoTitel}</span>`
          )
          element.english = `<span style="opacity: ${enOpacity};" id="msEnglish${index}">${element.english}</span>`
          if (element.chinese === null) {
            element.chinese = '无中文字幕'
          }
          element.chinese = `<span style="opacity: ${zhOpacity};" id="msChinese${index}">${element.chinese}</span>`
          element.isMsLoading = false
        }
        this.ModelSentenceList = res.data
        this.PageModelSentenceIndex = 0
        if (this.ModelSentenceList.length > 0) {
          if (this.ModelSentenceList.length < 20) {
            this.PageModelSentenceList = this.ModelSentenceList.slice(
              0,
              this.ModelSentenceList.length
            )
          } else {
            this.PageModelSentenceList = this.ModelSentenceList.slice(0, 20)
          }
        } else {
          this.PageModelSentenceList = []
        }
        this.msLogin = false
      })
    },
    // 合成例句发音
    onMsPlay(type, obj) {
      var that = this
      if (type === 1) {
        this.audio.src = `http://dict.youdao.com/speech?audio=${obj}`
        this.audio.play()
      } else if (type === 2) {
        obj.isMsLoading = true
        this.audio.src = obj.audio
        this.audio.currentTime = obj.startMillisecond / 1000
        var audioTimeupdate = function(event) {
          if (that.audio.currentTime >= obj.endMillisecond / 1000) {
            that.audio.pause()
            that.audio.removeEventListener('timeupdate', audioTimeupdate)
            that.audio.removeEventListener('canplay', canplay)
          }
        }
        var canplay = function() {
          obj.isMsLoading = false
        }
        this.audio.play()
        this.audio.addEventListener('timeupdate', audioTimeupdate)
        this.audio.addEventListener('canplay', canplay)
      }
    },
    // 搜索情景视频
    onSceneVideo() {
      this.isModelSentence = 3
      getAvName({
        name: this.youdaoTitel.trim()
      }).then(res => {
        if (res.data.exact) {
          this.sceneVideoUrl = res.data.exact.url
          this.scenarioVideoBut = true
          this.sceneVideoMd5 = res.data.exact.md5
          this.scenedim = res.data.dim
          this.onScenarioVideo(true)
        } else {
          this.scenarioVideoBut = false
          this.sceneVideoUrl = ''
          this.sceneVideoMd5 = ''
        }
      })
    },
    // 设置有道翻译字体
    onYoudaoTextSize(e) {
      localStorage.setItem('youdaoTextSize', e)
    },
    // 情景视频播放
    onScenarioVideo(isplay = false) {
      var that = this
      this.isScenarioVideo = true
      this.isFindIframe = false
      this.isSentence = false
      getSubtitle({ md5: this.sceneVideoMd5 }).then(res => {
        this.sceneVideoSubtitle = res.data
      })
      this.$nextTick(() => {
        // 播放事件
        this.$refs.sceneVideo.addEventListener('play', function() {
          that.$refs.sceneVideo.controls = true
          that.sceneVideoIsPlay = true
        })

        // 暂停事件
        this.$refs.sceneVideo.addEventListener('pause', function() {
          that.$refs.sceneVideo.controls = false
          that.sceneVideoIsPlay = false
        })
        if (isplay) {
          that.$refs.sceneVideo.play()
        }
      })
    },
    // 情景视频左键点击
    onsSceneVideoClickLeft() {
      if (!this.sceneVideoIsPlay) this.$refs.sceneVideo.play()
    },
    // 情景视频播放进度
    onTimeupdate(e) {
      const currentMillisecondCeil = Math.ceil(this.$refs.sceneVideo.currentTime * 1000)
      const currentSubtitle = this.sceneVideoSubtitle.find((x, index) => {
        if (x.startMillisecond <= currentMillisecondCeil) {
          return true
        }
        return false
      })
      this.subtitle = currentSubtitle
    },
    // 加载情景视频
    onDim(item) {
      this.sceneVideoUrl = item.url
      this.scenarioVideoBut = true
      this.sceneVideoMd5 = item.md5
      this.sceneVideoSubtitle = []
      this.subtitle = {}
      this.onScenarioVideo(true)
    },
    // 例句隐藏
    osMSOpacity(index) {
      if (document.getElementById('msEnglish' + index).style.opacity !== '1') {
        document.getElementById('msEnglish' + index).style.opacity = 1
      } else if (
        document.getElementById('msword' + index).style.opacity !== '1'
      ) {
        document.getElementById('msword' + index).style.opacity = 1
      } else if (
        document.getElementById('msChinese' + index).style.opacity !== '1'
      ) {
        document.getElementById('msChinese' + index).style.opacity = 1
      } else {
        var example = localStorage.getItem('example')
        if (example && example === '1') {
          document.getElementById('msword' + index).style.opacity = 0
        } else if (example && example === '2') {
          document.getElementById('msChinese' + index).style.opacity = 0
          document.getElementById('msword' + index).style.opacity = 0
        } else if (example && example === '3') {
          document.getElementById('msEnglish' + index).style.opacity = 0
          document.getElementById('msword' + index).style.opacity = 0
        } else if (!example || example === '4') {
          document.getElementById('msEnglish' + index).style.opacity = 0
          document.getElementById('msword' + index).style.opacity = 0
          document.getElementById('msChinese' + index).style.opacity = 0
        }
      }
    },
    // 英文字幕
    onEn() {
      this.isEn = !this.isEn
    },
    // 选择文件
    onOepnLocalFile() {
      this.$refs.localFileInput.dispatchEvent(new MouseEvent('click'))
    },
    // 处理文件改变
    async handleFileChange() {
      var that = this
      this.isDowJur = true
      var files = this.$refs.localFileInput.files
      // console.log(files)
      var md5s = []
      files.forEach((file) => {
        const spark = new SparkMD5.ArrayBuffer()
        const reader = new FileReader()
        const size = file.size
        const offset = 2 * 1024 * 1024
        const chunks = [file.slice(0, offset)]
        let cur = offset
        while (cur < size) {
          if (cur + offset >= size) {
            chunks.push(file.slice(cur, cur + offset))
          } else {
            const mid = cur + offset / 2
            const end = cur + offset
            chunks.push(file.slice(cur, cur + 2))
            chunks.push(file.slice(mid, mid + 2))
            chunks.push(file.slice(end - 2, end))
          }
          cur += offset
        }
        reader.readAsArrayBuffer(new Blob(chunks))
        reader.onload = function() {
          spark.append(this.result)
          var md5 = spark.end()
          md5s.push(md5)
          if (md5s.length === files.length) {
            dowJur({ M: md5s }).then(res => {
              that.isDowJur = false
              cartoonGrouping().then(res => {
                that.groupings = res.data
              })
            })
          }
        }
      })
    },
    // 学员删除绘本
    deCartoon() {
      console.log(this.deCartoonId)
      this.groupings.filter((item, index) => {
        if (item.id === this.deCartoonId) {
          this.groupings.splice(index, 1)
          deBook({ did: this.deCartoonId })
        }
      })
    },
    // 柯林斯英英词典
    onKls() {
      this.klsLoading = true
      this.getHtml(`https://www.collinsdictionary.com/zh/dictionary/english/${this.youdaoTitel}`).then((htmlText) => {
        if (htmlText !== null) {
          kls({ html: htmlText, htmlType: 'KeLinShi' }).then(res => {
            this.klsHtml = res.data
            this.klsLoading = false
          })
        } else {
          console.log('无法获取HTML内容')
        }
      })
    },
    // 获取 url-html内容
    async  getHtml(url) {
      try {
        const response = await fetch(url)

        if (!response.ok) {
          throw new Error('网络错误')
        }

        return await response.text()
      } catch (error) {
        console.log(`获取HTML失败：${error}`)
        return null
      }
    },
    // 柯林斯发音
    onKeLinShiAudio(url) {
      this.audio.src = url
      this.audio.play()
    },
    // 记录默认翻译顺序
    onHtmlType(e) {
      localStorage.setItem('HtmlType', e)
    },
    // Ai释义发音
    onPayAi(index) {
      speechSynthesisToken().then(res => {
        var url = `https://nls-gateway-cn-shanghai.aliyuncs.com/stream/v1/tts?appkey=lxOnXI4KNOvwNSI1&token=${res.data}&text=${this.aiExplainTexts[ index ]}&format=mp3&voice=cindy&volume=100`
        this.audio.src = url
        this.audio.play()
      })
    },
    // 阿里单词发言
    aliPay(type) {
      speechSynthesisToken().then(res => {
        var url = `https://nls-gateway-cn-shanghai.aliyuncs.com/stream/v1/tts?appkey=lxOnXI4KNOvwNSI1&token=${res.data}&text=${this.youdaoTitel}&format=mp3&voice=${type}&volume=100`
        this.audio.src = url
        this.audio.play()
      })
    }
  }
}
</script>
<style lang='less' scoped>
//禁止文本选择
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
//滚动条
.scrollbar {
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.layout-screen {
  margin: 0px !important;
  border-radius: 0px;
}

.back {
  position: absolute;
  left: 20px;
  top: 10px;
  background: #c61200;
  color: white;
  border-radius: 20px;
  height: 30px;
  z-index: 2;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 10px;
  font-size: 14px;
}
.grouping-name {
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #c61200;
  color: rgb(33, 33, 33);
  font-size: 14px;
  font-weight: bold;
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #e4e4e4;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  width: 182px;
}

.item-img {
  width: 180px !important;
  height: 200px !important;
}
.item-name {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.book-num {
  color: white;
  position: absolute;
  font-size: 12px;
  left: 0px;
  top: 8px;
  z-index: 1;
  padding: 4px 12px 4px 4px;
  border-radius: 0px 20px 20px 0px;
  border-color: rgba(255, 255, 255, 0.6);
  border-width: 1px;
  border-style: solid;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.content-chinese {
  font-size: 12px;
  background: #f73943;
  color: white;
  padding: 5px 14px;
  border-radius: 30px;
  position: absolute;
  z-index: 1;
  top: 30px;
}
.el-image {
  height: calc(100% - 100px);
  width: auto;
}
.content-index {
  font-size: 20px;
  background: #f2f2f2;
  border-radius: 40px;
  color: #3e3e3e;
  padding: 2px 20px;
}
.content-but {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}
.content-but-paly {
  background: rgb(45, 45, 45);
  border-radius: 50%;
  width: 60px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
.content-but-hint {
  position: absolute;
  left: 10px;
  bottom: 20px;
  font-size: 12px;
  color: rgb(86, 86, 86);
  z-index: 0;
}
.take-background {
  background: #f7dc00;
  color: #282828;
  // border-radius: 5px;
}
// 例句
.ms-row {
  margin: 10px 0px;
  padding: 5px;
  border-width: 0 0 1px 0;
  border-color: #ffffff62;
  border-style: solid;
  .ms-english {
    font-weight: bold;
    display: flex;
    align-items: center;
    // justify-content: center;
    // color: #ffffff;
    font-size: 14px;
    margin-bottom: 5px;
    .frequency {
      padding: 0px 8px;
      color: white;
      border-radius: 2px;
      font-size: 12px;
      font-weight: normal;
      margin-left: 4px;
    }
    .play {
      width: 28px;
      height: 28px;
      border-radius: 5px;
      // 左右上下居中
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  .ms-chinese {
    color: #646363;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .ms-provenance {
    color: #8b8b8b;
    font-size: 12px;
  }
}
.el-dialog__body {
    padding: 10px 20px !important;
}
</style>
<style>
/* .el-popper[x-placement^='top'] {
  background: #090909ed;
} */
.el-tooltip__popper {
  padding: 6px !important;
}
.el-slider__button {
  width: 0px !important;
  height: 0px !important;
}
.el-slider__runway {
  height: 2px;
  background-color: #d0d0d032 !important;
}
.el-slider__bar {
  height: 2px;
  background-color: #c61200 !important;
}
.el-slider__button {
  border: solid 4px #c61200 !important;
  margin-top: -4px !important;
}
.el-tooltip__popper {
  padding: 6px !important;
}
.el-dialog__body{
  padding: 10px 20px;
}
</style>
