import request from '@/utils/request'

// 搜索Ai释义
export function search(data) {
  return request({
    url: 'AiExplain/Search',
    params: data,
    method: 'Get'
  })
}
// 语音合成Token
export function speechSynthesisToken(data) {
  return request({
    url: 'AiExplain/SpeechSynthesisToken',
    params: data,
    method: 'Get'
  })
}
