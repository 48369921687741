import request from '@/utils/request'

// 绘本分组
export function cartoonGrouping() {
  return request({
    url: 'Book/CartoonGrouping',
    method: 'Get'
  })
}

// 绘本图书列表
export function bookCataList(data) {
  return request({
    url: 'Book/CartoonBooks',
    params: data,
    method: 'get'
  })
}

// 绘本图书内容
export function cartoonBookContent(data) {
  return request({
    url: 'Book/CartoonBookContent',
    params: data,
    method: 'Get'
  })
}

// 绘本权限
export function dowJur(data) {
  return request({
    url: 'Book/DowJur',
    data,
    method: 'POST'
  })
}

// 学员删除绘本
export function deBook(data) {
  return request({
    url: 'Book/DeBook',
    params: data,
    method: 'Get'
  })
}
